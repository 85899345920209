import React, { useEffect, useState } from "react";
import { Stack, Typography, Button, Radio, Row, Col, Spin } from 'witmeg-ui-system';
import paymentDetailBG from "../../assets/images/Images/payment-detail.svg"
import { useHistory } from 'react-router';
import "./PaymentStyles.scss"
import PaymentContainer from "./PaymentContainer";
import { useSelector, useDispatch } from 'react-redux';
import { getCartItemsToCart, placeOrder } from "../../utilities/api/restaurantsService";
import { setOrderEmpty } from "../../../../../../../redux/resturantWidgetSlice";
import { LOCAL_STORAGE_ITEMS_NAMES } from "../../utilities/helper/helper";
import { getRestaurantMenuSettingsData } from "../../../../../utilities/apiRequests/neutriPosServerRequests";
import { sendPaymentDetails } from "../../utilities/api/paymentService";

const PaymentSelectionPage = () => {
    const [isCardPayment, setIsCardPayment] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [paymentTypes, setPaymentTypes] = useState([])
    const [isPaymentLoading, setIsPaymentLoading] = useState(false)

    let history = useHistory();

    const dispatch = useDispatch()
    const onBackToMenu = () => history.goBack();
    const companyDetails = useSelector((state) => state.companyData.companyDetails);
    const restaurantDataState = useSelector((state) => state.resturantData);
    const orderID = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) ? localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) : ""
    const customerDetails = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CUSTOMER_DETAILS) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CUSTOMER_DETAILS)) : null
    const storeOrderLocalData = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)) : null

    const search = history.location.search;
    const params = new URLSearchParams(search);
    const redirectResult = params.get('redirectResult');

    const getPaymenttypes = async () => {
        const paymentSettings = await getRestaurantMenuSettingsData({
            SettingType: "PaymentMethods",
            CloudLocationID: storeOrderLocalData.cloudLocationID,
        })
        setPaymentTypes(paymentSettings.map((type) => type.PaymentMethod))
    }

    useEffect(() => {
        if (orderID === "" && customerDetails) {
            history.push("/")
        } else {
            getPaymenttypes()
            setIsCardPayment(redirectResult ? true : false)
        }
    }, [])
    useEffect(() => {
        if (redirectResult) {
            sendPaymentDetailsRedirect()
        }
    }, [])
    const sendPaymentDetailsRedirect = () => {
        const paymentDetailsRequest = {
            cloudLocationID: storeOrderLocalData.cloudLocationID,
            reference: `Online-${storeOrderLocalData.cloudLocationID}-Ecom-${orderID}`,
            siteTransactionRef: orderID,
            store: `Ecommerce_${storeOrderLocalData.cloudLocationID}`,
            countryCode: "GB",
            paymentDetailInput: {
                redirectResult: redirectResult

            },
            shopperReference: localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_USER_ID),

        }
        setIsPaymentLoading(true)
        sendPaymentDetails(paymentDetailsRequest, companyDetails.PaymentIntegration.IntegrationData.Client_Key).then((response) => {

            if (response.resultCode === "Authorised") {
                let customerID = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_USER_ID);
                getCartItemsToCart(customerID, storeOrderLocalData.cloudLocationID).then((response) => {
                    if (response) {
                        if (response.Payment.Status === "COMPLETED") {
                            placeCartOrder("CARD")
                        } else {
                            history.push(`/order/failed`)
                        }
                    }
                })
            }
        })

    }
    const placeCartOrder = (type) => {
        if (restaurantDataState.orderCartData.Items.length > 0) {
            setIsLoading(true)
            setIsPaymentLoading(true)
            let data = { ...restaurantDataState.orderDetails, PaymentType: type, OrderType: "MENU" }
            placeOrder(data).then((response) => {
                if (response) {
                    const oid = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID)
                    history.push(`/order/success/${oid}`)
                    dispatch(setOrderEmpty())
                    setIsLoading(false)
                    setIsPaymentLoading(false)

                }
            }).catch((error) => {
                setIsLoading(false)
                history.push(`/order/failed`)
            })

        }
    }
    return (
        paymentTypes.length === 0 ? <Spin tip="Loading"
            className="eco-mt-44 spinner" size="large"> <div className="content" /></Spin>
            :
            <div className="PaymentStyles">
                {isPaymentLoading &&
                    <div className="overlayer" ><div className="order-placing">Please wait order in process ...</div></div>
                }
                <div className='order-confirmation-page'>
                    <Button className="eco-order-confirm-back-but eco-mb-24" category="icon-text" icon="chevron-left" text="Back to menu" iconSize={16} variant="subtle" onClick={onBackToMenu} />
                    <Stack horizontalAlign="space_between">
                        <Typography className="eco-mb-32 eco-order-confirm-title" type="title" level={1} color="gray_darkest">Payment Detail</Typography>
                    </Stack>
                    <div className="eco-mb-16 payment-container" >
                        <div className="form-header">Payment Method</div>
                        <div className="payment-form-container">
                            <Row>
                                <Col span={12}>
                                    <Row className="eco-mb-24" gutter={[0, 32]}>
                                        <Col lg={12} sm={24} xs={24}>
                                            <Row gutter={[0, { xs: 32 }]}>
                                                {paymentTypes.includes("card") && <Col lg={12} sm={24} xs={24}>
                                                    <Radio value="card-payment" checked={isCardPayment} onChange={() => { setIsCardPayment(!isCardPayment) }}>Card Payment</Radio>
                                                </Col>}
                                                {paymentTypes.includes("cash") && <Col lg={12} sm={24} xs={24}>
                                                    <Radio value="cash-payment" checked={!isCardPayment} onChange={() => { setIsCardPayment(!isCardPayment) }}>Pay Later</Radio>
                                                </Col>}
                                            </Row>
                                            {isCardPayment && companyDetails.PaymentIntegration && companyDetails.PaymentIntegration.
                                                IntegrationName === "Wittally" && !redirectResult &&
                                                <div style={{ marginTop: "34px" }}>  <PaymentContainer /></div>
                                            }
                                            {!isCardPayment &&
                                                <Stack className="eco-mt-80">
                                                    <Button
                                                        category={isLoading ? "load-text" : "icon-text"}

                                                        className="full-width-btn" variant="primary" onClick={() => {
                                                            if (!isLoading) {
                                                                placeCartOrder("CASH_ON_DELIVERY")
                                                            }
                                                        }} text="Confirm and Place Order" />
                                                </Stack>}
                                            {redirectResult &&
                                                <Stack className="eco-mt-80">
                                                    <Button
                                                        category={"load-text"}
                                                        className="full-width-btn"
                                                        variant="primary"
                                                        text="Payment Inprocess"
                                                        style={{ width: "250px !important" }} />
                                                </Stack>}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12} className="payment-details-bg">
                                    <img src={paymentDetailBG} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
    )

}
export default PaymentSelectionPage
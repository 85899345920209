import React, { useEffect, useState } from "react";
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { LOCAL_STORAGE_ITEMS_NAMES } from "../../utilities/helper/helper";
import { getStorePaymentDetails, passPaymentMethodToDropin } from "../../utilities/api/paymentService";
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { placeOrder } from "../../utilities/api/restaurantsService";
import { setOrderEmpty } from "../../../../../../../redux/resturantWidgetSlice";
import { useHistory } from 'react-router';
import globalValues from "../../../../../utilities/apiRequests/globalValues";


const PaymentContainer = () => {
    const dispatch = useDispatch()
    let history = useHistory();
    const storeOrderLocalData = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)) : null
    const companyDetails = useSelector((state) => state.companyData.companyDetails);
    const [paymentMethods, setPaymentMethods] = useState(null)
    const customerDetails = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CUSTOMER_DETAILS) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CUSTOMER_DETAILS)) : {}
    const orderID = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) ? localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) : ""
    const restaurantDataState = useSelector((state) => state.resturantData);

    // const getTotalPrice = (
    //     baseTotalPrice
    // ) => {
    //     let priceValue;
    //     switch (restaurantDataState.orderDetails.FulfillmentType) {
    //         case "DELIVERY_BY_REWARDCONSUMER":
    //             priceValue = baseTotalPrice.Delivery.Amount;
    //             break;
    //         case "DINE_IN":
    //             priceValue = baseTotalPrice.DineIn.Amount;
    //             break;
    //         case "TAKE_AWAY":
    //             priceValue = baseTotalPrice.TakeAway.Amount;
    //             break;
    //         default:
    //             priceValue = baseTotalPrice.Delivery.Amount;
    //             break;
    //     }
    //     return priceValue
    // }

    const total = restaurantDataState.orderDetails.Payment.
        PaymentDetail.OrderTotal.Gross.AmountE5 ?? 0

    const placeCartOrder = () => {
        if (restaurantDataState.orderCartData.Items.length > 0) {
            let data = { ...restaurantDataState.orderDetails, PaymentType: "CARD", OrderType: "MENU" }
            placeOrder(data).then((response) => {
                if (response) {
                    const oid = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID)
                    history.push(`/order/success/${oid}`)
                    dispatch(setOrderEmpty())

                }
            }).catch((error) => {
                history.push(`/order/failed`)
            })

        }
    }

    useEffect(() => {
        if (storeOrderLocalData && orderID !== "") {
            let ref = uuidv4();
            const requestBody = {
                clientAPIRequestRef: ref,
                cloudLocationID: storeOrderLocalData.cloudLocationID,
                store: `Ecommerce_${storeOrderLocalData.cloudLocationID}`
            }
            getStorePaymentDetails(requestBody, companyDetails.PaymentIntegration.IntegrationData.Client_Key).then((response) => {
                if (response) {
                    setPaymentMethods(response)
                }
            })
        }

    }, [])

    const makeInstantPayment = async (data) => {
        return new Promise((resolve) => {
            const uniqueID = uuidv4()
            const { paymentMethod } = data
            const paymentRequestBody = {
                amount: {
                    currency: "GBP",
                    value: total
                },
                cloudLocationID: storeOrderLocalData.cloudLocationID,
                reference: `Online-${storeOrderLocalData.cloudLocationID}-Ecom-${uniqueID}`,
                siteTransactionRef: orderID,
                returnUrl: window.location.href,
                store: `Ecommerce_${storeOrderLocalData.cloudLocationID}`,
                countryCode: "GB",
                shopperEmail: customerDetails.Email,
                ShopperName: `${customerDetails.Name.FirstName} ${customerDetails.Name.LastName}`,
                telephoneNumber: `${customerDetails.Contact.PinCode}${customerDetails.Contact.Number}`,
                paymentMethod,
                isTokenized: false,
                APISource: "Web",
                shopperReference: localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_USER_ID),
                additionalData: {
                    "allow3DS2": "true"
                },
            }
            passPaymentMethodToDropin(paymentRequestBody, companyDetails.PaymentIntegration.IntegrationData.Client_Key).then((response) => {
                resolve(response)
            }).catch(error => {
                history.push(`/order/failed`)
            });
        })

    }

    const handleTransaction = (stage) => {
        if (stage === "Authorised" || stage === "Received" || stage === "AuthenticationNotRequired") {
            placeCartOrder()
        }
        else if (stage === "ChallengeShopper" || stage === "PresentToShopper" || stage === "AuthenticationNotRequired" || stage === "RedirectShopper" || stage === "IdentifyShopper") {


        } else {
            history.push(`/order/failed`)
        }
    }

    useEffect(() => {
        const initCheckout = async () => {

            const configuration = {
                paymentMethodsResponse: paymentMethods, // The `/paymentMethods` response from the server.
                clientKey: globalValues.payment.REACT_APP_PAYMENT_CLIENT_KEY, // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
                locale: "en-US",
                environment: globalValues.payment.REACT_APP_PAYMENT_CLIENT_ENVIRONMENT,
                analytics: {
                    enabled: true // 
                },
                onSubmit: (state, dropin) => {
                    if (state.data.storePaymentMethod === false) {
                        makeInstantPayment(state.data)
                            .then((response) => {
                                if (response.action) {
                                    dropin.handleAction(response.action);
                                } else {
                                    handleTransaction(response.resultCode)
                                }
                            })

                    }
                },
                paymentMethodsConfiguration: {
                    card: {
                        hasHolderName: true,
                        holderNameRequired: true,
                        enableStoreDetails: true,
                        hideCVC: false,
                        name: 'Credit or debit card',
                    }
                }
            };

            const checkout = await AdyenCheckout(configuration);
            checkout
                .create('dropin', {
                    openFirstPaymentMethod: false,
                })
                .mount('#dropin-container');

        }
        if (paymentMethods)
            initCheckout()
    }, [paymentMethods])

    return <div id="dropin-container"></div>
}
export default PaymentContainer
import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Space, Popover, Badge } from 'antd';
import { Button, Select } from 'witmeg-ui-system'
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import _ from "lodash";
import "@fontsource/inter";
import "@fontsource/jost";
import config from "react-global-configuration";
import './Navbar.scss';
import { useHistory } from 'react-router-dom';
import { getCompanyAllPagesInfo, updateOrderTypeDetails } from '../../../RestaurantWidget/utilities/api/restaurantsService';
import profile from "../../assets/profile.svg"
import arrow from "../../assets/down-arrow.svg"
import menu from "../../assets/menu.svg"


import { setDefaultCloudLocation, setOrderData, setOrderEmpty, setVoucherData } from '../../../../../../../redux/resturantWidgetSlice';
import { isECommerceProcessPath, LOCAL_STORAGE_ITEMS_NAMES } from '../../../RestaurantWidget/utilities/helper/helper';
import CartPopup from '../Cart/CartPopup';
import { getOrgDetails } from '../../../RestaurantWidget/utilities/api/voucherService';
const Navbar = ({ setIsDrawerOpen }) => {

    const dispatch = useDispatch()
    const data = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_ALL_MENU_DATA) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_ALL_MENU_DATA)).data : {}
    const [items, setItems] = useState([])
    const history = useHistory()
    const [state, setState] = useState({
        menusize: 0,
        menus: "",
        pages: [],
        categories: [],
        brands: [],
        basketitem: [],
        // hasResturantWidget: null,
        cloudLocations: [],
        locationsDropdown: [],
        selectedLocation: {},
        visibleCartSummaryPopup: false,
        showDropdown: false,
        redirect: false,
        activeDropdown: null,

        locations: [],
        defaultLocation: {},

        updateCartItemPopup: {
            visible: false,
            data: null,
            index: null,
            editData: null,
        },
    });


    const restaurantDataState = useSelector((state) => state.resturantData);
    const companyDataState = useSelector((state) => state.companyData);
    const resize = () => {
        if (window.innerWidth > 1681) {
            setState((prevState) => ({ ...prevState, menusize: 5 }));
        } else if (window.innerWidth < 1400 && window.innerWidth > 1150) {
            setState((prevState) => ({ ...prevState, menusize: 2 }));
        } else {
            setState((prevState) => ({ ...prevState, menusize: 3 }));
        }
    };
    const [isOpen, setIsOpen] = useState(false);
    const popoverRef = useRef(null);
    const containerRef = useRef(null);
    const togglePopover = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        window.addEventListener("resize", resize);
        resize();

        const _initData = async () => {
            const __response = await getCompanyAllPagesInfo({
                ID: "",
                CompanyID: config.get("companyID"),
            });

            let __pages = __response
                .filter((z) => z.DraftMainPagesInfoID === "")
                .map((y) => {
                    return {
                        ...y,
                        children: __response.filter(
                            (a) => a.DraftMainPagesInfoID === y.ID
                        ),
                    };
                });
            setState((prevState) => ({ ...prevState, pages: __pages }));
        };
        _initData();
    }, []);



    useEffect(() => {
        if (
            restaurantDataState.resturantCloudLocations &&
            restaurantDataState.resturantCloudLocations.length > 0 &&
            restaurantDataState.defualtSelectedLocation
        ) {
            const _locations = restaurantDataState.resturantCloudLocations.map(
                (__location) => ({
                    label: __location.CloudLocationName,
                    value: __location.CloudLocationID,
                })
            );

            const _defaultLocation = _.find(_locations, {
                value: restaurantDataState.defualtSelectedLocation
                    .CloudLocationID,
            });

            setState((prevState) => ({
                ...prevState,
                locations: _locations,
                defaultLocation: _defaultLocation,
                selectedLocation: _defaultLocation,
            }));
        }
    }, [
        restaurantDataState.resturantCloudLocations,
        restaurantDataState.defualtSelectedLocation,
    ]);

    useEffect(() => {
        if (companyDataState && companyDataState.companyDetails && companyDataState.companyDetails.organizationID) {
            axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
                isLoginServerRequest: false,
                serviceName: "productRegistry",
            }).then(async function (authresponse) {
                console.log("TOKEN response", authresponse)
                const requestOptions = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authresponse.data.token}`,
                    }
                };

                let OrgID = companyDataState.companyDetails.organizationID

                axios.get(`${process.env.REACT_APP_rest_API_productregistry}/onlinecategories/${OrgID}`, requestOptions).then((categoryData) => {
                    if (categoryData.data.Status) {
                        console.log(categoryData.data.Result)
                    }
                })
            })
        }
    }, [companyDataState.companyDetails])

    useEffect(() => {
        if (data.Categories && data.Categories.length > 0) {
            let array = data.Categories.map((val) => {
                return val.MenuEntities.map((menuEntity) => {
                    const menuItem = _.find(data.Items, { MenuItemID: menuEntity.ID });
                    return { ...menuItem, Category: val.Title.en };
                });
            }).flat();

            setItems(array)
        }
    }, [])

    const toggleCartSummaryPopover = () => {
        setState((prevState) => ({
            ...prevState,
            visibleCartSummaryPopup: !prevState.visibleCartSummaryPopup,
        }));
    };

    const changeUpdateCartItemPopupVisibility = (_visible, _data = null) => {
        if (_visible) {
            setState((prevState) => ({
                ...prevState,
                updateCartItemPopup: {
                    visible: true,
                    data: _data.data,
                    editData: _data.editData,
                    index: _data.index,
                },
            }));
            toggleCartSummaryPopover();
        } else {
            setState((prevState) => ({
                ...prevState,
                updateCartItemPopup: {
                    visible: false,
                    data: null,
                    editData: null,
                    index: null,
                },
            }));
        }
    };



    const handleLogout = () => {
        sessionStorage.clear()
        localStorage.clear()
        window.location.href = '/'
    };
    const handleChange = async (value) => {
        setState((prevState) => ({
            ...prevState,
            defaultLocation: value,
            selectedLocation: value,
        }))
        let newData = {
            CloudLocationID: value.value,
            CloudLocationName: value.label
        }

        if (restaurantDataState.defualtSelectedLocation.CloudLocationID !== value.value) {
            dispatch(setDefaultCloudLocation({ defualtSelectedLocation: newData }))
            const orgData = await getOrgDetails(newData.CloudLocationID)
            if (orgData) {
                dispatch(setVoucherData(orgData))
            }
            dispatch(setOrderEmpty())
        }

    }; return (
        <div className='Voucher-navbar'>
            <div className="Voucher-navbar-fixed-header">
                <Row align="middle">
                    <Col xs={5} className='Voucher-navbar-menu-logo'>
                        <img
                            className="menu-icon-cont"
                            src={menu}
                            alt="Menu Icon"
                            onClick={() => {
                                if (window.screen.width > 600) {
                                    togglePopover()
                                } else {
                                    setIsDrawerOpen(true)
                                }
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                        <div className="popover" ref={popoverRef}>
                            {isOpen ? <div className={`popover-content`}>
                                <div className='track-order-ntag' onClick={() => {
                                    history.push("/")
                                    setIsOpen(false)
                                }}>Voucher</div>
                                {!isECommerceProcessPath() &&
                                    state.pages.map((pn, index) => (
                                        <>
                                            {pn.children.length === 0 &&
                                                pn.ShowInMainMenu === true && (
                                                    <div key={index} className='track-order-ntag' onClick={() => {
                                                        history.push(`/pages/${pn.Slug}`)
                                                        setIsOpen(false)
                                                    }}>{pn.PageName}</div>

                                                )}
                                        </>
                                    ))
                                }
                                {localStorage.getItem('isLogged') === 'true' && <div className='track-order-ntag' onClick={() => {
                                    history.push("/order/history")
                                    setIsOpen(false)
                                }}>Order history</div>}

                            </div> : <div className='popover-content-close' />}
                        </div>
                        {/* <Drawer /> */}

                        <div className="Voucher-navbar-logo">

                            <a
                                className="eco-navbar-logo"
                                href={!restaurantDataState.hasResturantWidget && `/`}
                            >
                                <img
                                    style={{ maxHeight: "48px" }}
                                    src={config.get("companyLogo")}
                                    draggable="false"
                                />
                            </a>
                        </div>
                        <Space className='Voucher-navbar-menu-tab'>
                            <a href="/" style={{ color: "white" }}>Voucher</a>
                            {!isECommerceProcessPath() &&
                                state.pages.map((pn, index) => (
                                    <>
                                        {pn.children.length === 0 &&
                                            pn.ShowInMainMenu === true && (
                                                <a
                                                    href={`/pages/${pn.Slug}`}
                                                    style={{ color: "white" }}
                                                >
                                                    {pn.PageName}
                                                </a>
                                            )}
                                    </>
                                ))
                            }
                        </Space>
                    </Col>

                    <Col xs={14} className='Voucher-navbar-menu'>
                        {/* <Select
                            value={state.selectedLocation}
                            onChange={handleChange}
                            name="type"
                            options={state.locations}
                            defaultValue={state.defaultLocation}
                            className="dropdown"
                        /> */}

                    </Col>
                    <Col xs={5} className='Voucher-navbar-user-prof'>
                        <Row>
                            <Col>
                                <Row align="middle">
                                    <Col className='Voucher-navbar-sign-in-menu'>
                                        <div className='Voucher-navbar-sign-in-menu-icon'>
                                            <img alt='' src={profile} className='Voucher-navbar-sign-in-menu-icon-profile' />
                                        </div>
                                        <div>
                                            {localStorage.getItem('isLogged') === 'true' ?
                                                < >
                                                    <div className='my-account-text'>{JSON.parse(localStorage.getItem('fullUserDetails')).FirstName} {JSON.parse(localStorage.getItem('fullUserDetails')).LastName}</div>
                                                    <div className='log-text' onClick={handleLogout} style={{ color: "#949494", cursor: "pointer" }}>Logout</div>
                                                </>
                                                :
                                                <>
                                                    <div className='my-account-text'>MY ACCOUNT</div>
                                                    <div className='log-text'>
                                                        <a href="/account/login" style={{ color: "#949494" }}>Login</a>
                                                        <div className='border-line-sign' />
                                                        <a href="/account/register" style={{ color: "#949494" }}>Signup</a>
                                                    </div>
                                                </>

                                            }
                                        </div>

                                    </Col>
                                </Row>
                            </Col>
                            <Col className='Voucher-navbar-order'>
                                <Badge
                                    className="eco-navbar-toggle"
                                    count={
                                        restaurantDataState.cartDetails &&
                                            restaurantDataState.cartDetails.items
                                            ? restaurantDataState.cartDetails
                                                .items.length
                                            : 100
                                    }
                                    size="small"
                                    overflowCount={99}
                                    style={{ transform: "translate(5%, -27%)", height: '18px', width: '18px' }}
                                >
                                    <Popover
                                        content={
                                            <CartPopup
                                                toggleVisibility={
                                                    toggleCartSummaryPopover
                                                }
                                                updateCartItemCallback={
                                                    changeUpdateCartItemPopupVisibility
                                                }
                                                websiteType={companyDataState.companyDetails.websiteType}
                                            />
                                        }
                                        trigger="click"
                                        placement="bottomRight"
                                        visible={state.visibleCartSummaryPopup}
                                        onVisibleChange={() => {
                                            if (
                                                state.visibleCartSummaryPopup ===
                                                true
                                            ) {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    visibleCartSummaryPopup: false,
                                                }));
                                            }
                                        }}
                                        overlayClassName="eco-order-summary eco-cart-summary"
                                    >
                                        <div className='Voucher-navbar-cart-nav-item'>

                                            <div className='Voucher-navbar-cart-icon' onClick={toggleCartSummaryPopover} >
                                                <Button
                                                    category="icon"
                                                    variant="link"
                                                    children="cart"
                                                    color="white"

                                                />
                                                {restaurantDataState?.orderCartData?.Items && restaurantDataState?.orderCartData?.Items?.length > 0 && < div className='Voucher-navbar-noti-item'>{restaurantDataState?.orderCartData?.Items?.length}</div>}
                                            </div>
                                            <div className='Voucher-navbar-history-icon' onClick={() => {
                                                // history.push("/order/track")
                                            }}><img alt='' src={arrow} className='Voucher-navbar-history' /></div>

                                        </div>
                                    </Popover>
                                </Badge>
                            </Col>
                        </Row>

                    </Col>
                </Row>

            </div>
        </div >
    );
};

export default Navbar;